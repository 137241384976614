// Import necessary React features and third-party libraries.
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
	Suspense,
} from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// React Table functionalities
import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import Airtable from "airtable";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { MdEdit, MdOutlineClear } from "react-icons/md";
import { TbSortAscending, TbSortDescending } from "react-icons/tb";
import DesktopLogo from "../images/mdi_sort.svg";
import Filters from "./Filters";
import { AiOutlinePlusCircle } from "react-icons/ai";
import AccountBox from "./account-box";
import LeftNavbar from "./left-navbar";
import "react-quill/dist/quill.snow.css";
import { v4 as uuidv4 } from "uuid";
import Skeleton from "react-loading-skeleton";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import CountriesList from "./countries-list";
import { Form } from "react-bootstrap";
import axios from "axios";

// Lazy loading for ReactQuill.
const ReactQuill = React.lazy(() => import("react-quill"));

const CancellationTermTable = () => {
	// State variables for various functionalities.
	// These handle data, UI states, form inputs, and other component behaviors.
	const [data, setData] = useState([]);
	const [dataRecords, setDataRecords] = useState([]);

	const [loadForm, setLoadForm] = useState(false);
	const [disableLoad, setDisableLoad] = useState(false);
	const [show, setShow] = useState(false);
	const [show2, setShow2] = useState(false);
	const [selectedEventId, setSelectedEventId] = useState("");
	const [selectedEvent, setSelectedEvent] = useState("");
	const [filtering, setFiltering] = useState("");
	const [selectedTermsName, setSelectedTermsName] = useState("");
	const [selectedTermsCode, setSelectedTermsCode] = useState("");
	const [selectedTermsCountry, setSelectedTermsCountry] = useState("");
	const [selectedTermsLanguage, setSelectedTermsLanguage] = useState("");
	const [selectedTermsContent, setSelectedTermsContent] = useState("");
	const [newTermsName, setNewTermsName] = useState("");
	const [newTermsCode, setNewTermsCode] = useState("");
	const [newTermsCountry, setNewTermsCountry] = useState("");
	const [newTermsLanguage, setNewTermsLanguage] = useState("");
	const [newTermsContent, setNewTermsContent] = useState("");
	const [selectedTermsId, setSelectedTermsId] = useState("");
	const [updates, setUpdates] = useState(0);

	// Callbacks for modal open/close actions.
	const handleClose = useCallback(() => setShow(false), []);
	const handleShow = useCallback(() => setShow(true), []);
	const handleClose2 = useCallback(() => setShow2(false), []);
	const handleShow2 = useCallback(() => setShow2(true), []);

	// useEffect hooks for data fetching, updating UI based on state changes, etc.
	// These hooks are crucial for responsive and dynamic UI behavior based on state and data.
	useEffect(() => {
		const temp = data.find((element) => element.uniqueId === selectedEventId);
		setSelectedEvent(temp);
	}, [data, selectedEventId]);

	useEffect(() => {
		setSelectedTermsName(selectedEvent?.documentName);
		setSelectedTermsCode(selectedEvent?.code);
		setSelectedTermsCountry(selectedEvent?.country);
		setSelectedTermsLanguage(selectedEvent?.language);
		setSelectedTermsContent(selectedEvent?.content);
	}, [selectedEvent]);
	useEffect(() => {
		if (disableLoad === true) {
			setTimeout(() => {
				setLoadForm(true);
			}, 500);
		}
	}, [disableLoad]);

	// useEffect hook from React, used to perform side effects in function components.
	useEffect(() => {
		// fetchData is an async function for fetching data from a server.
		const fetchData = async () => {
			try {
				// Using axios to send a POST request to the serverless function.
				const response = await axios.post(
					"/.netlify/functions/cancel-term-table-fetch", // The endpoint of the serverless function.
					{}, // An empty object as the request body.
					{
						headers: {
							"Content-Type": "application/json", // Setting the content type of the request.
							"x-api-key": process.env.GATSBY_CANCELTERMTABLE_KEY_1, // API key for authentication, stored in environment variables.
						},
					}
				);

				// console.log(response); // Logging the response to the console.

				// Processing the response data.
				const tempEventArray = response.data.map((record) => record.fields); // Extracting fields from each record.
				const fullObjArray = response.data; // The full response data.

				// Setting state with the fetched data.
				setData(tempEventArray);
				setDataRecords(fullObjArray);
			} catch (err) {
				// Error handling if the fetch request fails.
				alert(
					"Could not retrieve data. Please wait 30 seconds and refresh the page if the problem persists please contact your delegate manager"
				);
				// console.error(err); // Logging the error to the console.
			}
		};

		// Executing fetchData and then setting a state when the promise resolves.
		fetchData().then(() => {
			setDisableLoad(true); // Changing state to indicate data loading is complete.
		});
	}, [updates]); // The hook runs when the 'updates' dependency changes.

	// Asynchronous function to handle button click event.
	const handleButtonClick = async () => {
		// Find the record in 'dataRecords' that matches the 'selectedEvent' by its unique ID.
		const temp = dataRecords.find(
			(element) => element.fields.uniqueId === selectedEvent.uniqueId
		);

		try {
			// Check if all required fields are filled and content length is more than 30 characters.

			if (
				selectedTermsCode &&
				selectedTermsName &&
				selectedTermsCountry &&
				selectedTermsLanguage &&
				selectedTermsContent.length > 30
			) {
				// Prompt the user for confirmation before saving.

				const confirmation = window.confirm("Are you sure you want to save?");
				if (confirmation) {
					// Send a POST request to update the data.

					const response = await fetch(
						"/.netlify/functions/cancel-term-table-update", // The endpoint for the update function.
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								"x-api-key": process.env.GATSBY_CANCELTERMTABLE_KEY_2, // API key for authentication.
							},
							body: JSON.stringify({
								id: temp.id, // The ID of the record to update.
								fields: {
									// The data to be updated.
									code: selectedTermsCode,
									documentName: selectedTermsName,
									country: selectedTermsCountry,
									language: selectedTermsLanguage,
									content: selectedTermsContent,
								},
							}),
						}
					);
					// Check if the response is not OK (indicating an error).
					if (!response.ok) {
						throw new Error("Network response was not ok");
					}

					// console.log("Value updated successfully!");
					setUpdates(updates + 1); // Increment the 'updates' state to trigger a re-fetch or re-render.
					handleClose(); // Close the modal or reset the state related to the form.
				}
			} else {
				// Alert if not all fields are filled.
				alert("Please fill in all fields");
			}
		} catch (error) {
			// Catch and handle any errors during the fetch process.
			alert(
				"Could not update data in Airtable. Please wait 30 seconds and try again .  Error: " +
					error.message
			);
			// console.error("Error updating value:", error);
		}
	};

	// Asynchronous function to handle the creation of new records.

	const handleButtonClick2 = async () => {
		try {
			// Check if all required input fields are filled and the content length is more than 30 characters.

			if (
				newTermsCode &&
				newTermsName &&
				newTermsCountry &&
				newTermsLanguage &&
				newTermsContent.length > 30
			) {
				// Ask for user confirmation before proceeding.

				const confirmation = window.confirm("Are you sure you want to save?");
				if (confirmation) {
					// Send a POST request to the serverless function for creating a new record.

					const response = await fetch(
						"/.netlify/functions/cancel-term-table-create", // Endpoint for the create function.
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								"x-api-key": process.env.GATSBY_CANCELTERMTABLE_KEY_3, // API key for authentication.
							},
							body: JSON.stringify({
								// Data to be sent in the request body.
								fields: {
									code: newTermsCode,
									documentName: newTermsName,
									country: newTermsCountry,
									language: newTermsLanguage,
									content: newTermsContent,
								},
							}),
						}
					);
					// Check if the server response indicates an error.
					if (!response.ok) {
						throw new Error("Network response was not ok");
					}
					// Update the state to reflect the creation of a new record.
					setUpdates(updates + 1);

					// Reset the input fields to their initial state.
					setNewTermsName("");
					setNewTermsCode("");
					setNewTermsCountry("");
					setNewTermsLanguage("");
					setNewTermsContent("");

					// Close the modal or reset the state related to the form.
					handleClose2();

					// console.log("New record created successfully!");
				}
			} else {
				// Alert the user if not all required fields are filled.
				alert("Please fill in all fields");
			}
		} catch (error) {
			// Catch and handle errors during the record creation process.
			alert(
				"Could not update data in Airtable. Please wait 30 seconds and try again. Error: " +
					error.message
			);
			// console.error("Error creating new record:", error);
		}
	};

	// Column definitions for the react-table.
	const columns = [
		{
			accessorKey: "code",
			header: "Code",
			size: 100,

			cell: (props) => (
				<p style={{ fontSize: "90%" }} className="pb-0 mb-0">
					{props.getValue()}
				</p>
			),
			enableGlobalFilter: true,
		},

		{
			enableGlobalFilter: true,
			accessorKey: "documentName",
			header: "Document name",
			cell: (props) => (
				<p style={{ fontSize: "90%" }} className="pb-0 mb-0">
					{props.getValue()}
				</p>
			),
		},
		{
			enableGlobalFilter: true,
			accessorKey: "country",
			header: "Country",
			size: 100,
			cell: (props) => (
				<p style={{ fontSize: "90%" }} className="pb-0 mb-0">
					{props.getValue()}
				</p>
			),
		},
		{
			enableGlobalFilter: true,
			accessorKey: "language",
			header: "Language",
			size: 100,
			cell: (props) => (
				<p style={{ fontSize: "90%" }} className="pb-0 mb-0">
					{props.getValue()}
				</p>
			),
		},
		{
			enableGlobalFilter: false,
			accessorKey: "edit",
			header: "Edit",
			size: 100,
			cell: (props) => (
				<div>
					<MdEdit
						onClick={() => {
							setSelectedEventId(props.row.original.uniqueId);
							// console.log(props.row);
							handleShow();
						}}
						style={{ cursor: "pointer" }}
					/>
				</div>
			),

			enableSorting: false,
		},
	];

	// Initialization of the react-table using the useReactTable hook.
	const table = useReactTable({
		data,
		columns,
		state: {
			globalFilter: filtering,
		},
		onGlobalFilterChange: setFiltering,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		columnResizeMode: "onChange",
		meta: {
			// Functions to update and delete data.
			updateData: (rowIndex, columnId, value) => {
				setData((prev) => {
					return prev.map((row, index) =>
						index === rowIndex ? { ...row, [columnId]: value } : row
					);
				});
			},
			deleteData: (code) => {
				setData((prev) => prev.filter((item) => item.uniqueId !== code));
			},
		},
	});

	// Function to render sorting icons in the table headers.
	const renderSortIcon = (column) => {
		const isSorted = column.getIsSorted();
		const canSort = column.getCanSort();

		if (!canSort) {
			return null;
		}

		if (!isSorted) {
			return (
				<img
					src={DesktopLogo}
					alt="Sort"
					style={{ width: "15px", cursor: "pointer" }}
					onClick={column.getToggleSortingHandler()}
					className="ms-2"
				/>
			);
		}

		if (isSorted === "asc") {
			return (
				<TbSortAscending
					onClick={column.getToggleSortingHandler()}
					style={{ width: "15px", cursor: "pointer", height: "15px" }}
					className="ms-2"
				/>
			);
		}

		if (isSorted === "desc") {
			return (
				<TbSortDescending
					onClick={column.getToggleSortingHandler()}
					style={{ width: "15px", cursor: "pointer", height: "15px" }}
					className="ms-2"
				/>
			);
		}

		return null;
	};

	// Asynchronous function to handle the deletion of a record.
	const handleDelete = async (event, eventCode) => {
		// Confirmation dialog to ensure the user wants to proceed with deletion.
		if (window.confirm("Are you sure you want to delete?")) {
			// Finding the record in 'dataRecords' that matches the unique ID of the event.
			const temp = dataRecords.find(
				(element) => element.fields.uniqueId === event.uniqueId
			);

			try {
				// Sending a POST request to the serverless function to delete the record.
				const response = await fetch(
					"/.netlify/functions/cancel-term-table-delete", // Endpoint for the delete function.
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							"x-api-key": process.env.GATSBY_CANCELTERMTABLE_KEY_4, // API key for authentication.
						},
						body: JSON.stringify({ id: temp.id }), // Sending the ID of the record to be deleted.
					}
				);

				// Checking if the response from the server indicates a failure.
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}

				// Logging the deletion.
				// console.log("Deleted record", temp.id);

				// Updating the table to reflect the deletion.
				table.options.meta?.deleteData(event.uniqueId);

				// Closing the modal or resetting the state related to the form (if applicable).
				handleClose();
			} catch (err) {
				// Handling errors during the fetch operation.
				alert(
					"Could not update data in Airtable. Please wait 30 seconds and try again. Error: " +
						err.message
				);
				// console.error(err);
			}
		}
	};

	// Function to render the table header.
	const renderTableHeader = () => {
		return table.getHeaderGroups().map((headerGroup) => (
			<thead key={headerGroup.id}>
				<tr>
					{headerGroup.headers.map((header) => (
						<th
							key={header.id}
							className="text-center pt-3 fw-bold pb-3"
							style={{
								width: header.getSize(),
								backgroundColor: "#FBFBFB",
							}}
						>
							{header.column.columnDef.header}
							{renderSortIcon(header.column)}
						</th>
					))}
				</tr>
			</thead>
		));
	};

	// Function to render the body of the table.
	const renderTableBody = () => {
		return table.getRowModel().rows.map((row) => (
			<tr key={row.id} className="text-center">
				{row.getVisibleCells().map((cell) => (
					<td
						key={cell.id}
						className="py-3"
						style={{ width: cell.column.getSize() }}
					>
						{flexRender(cell.column.columnDef.cell, cell.getContext())}
					</td>
				))}
			</tr>
		));
	};

	return (
		<section>
			<Modal
				style={{ zIndex: 99999 }}
				size="xl"
				show={show}
				onHide={handleClose}
				centered
			>
				<Modal.Body className=" p-5" closeButton>
					<div className="position-relative">
						<div className="position-absolute  start-0 top-0 ">
							<MdOutlineClear
								style={{ cursor: "pointer" }}
								className="fs-4"
								onClick={handleClose}
							/>
						</div>
						<div className="text-center">
							<input
								style={{
									borderTop: "0px",
									borderRight: "0px",
									borderLeft: "0px",
									borderBottom: "0px",
								}}
								className="fs-3 w-80 text-center fw-bold"
								type="text"
								value={selectedTermsName}
								onChange={(e) => setSelectedTermsName(e.target.value)}
							/>
						</div>
						<hr />
						<Row>
							<Col className="text-center">
								<p className="fw-bold pb-1 mb-0">Code:</p>
								<input
									style={{
										border: "none",
										borderBottom: "1px solid #255DAB",
										borderRadius: "0px",
										color: "#787878",
										width: "180px",
									}}
									className="fs-6  text-center text-med-grey "
									type="text"
									value={selectedTermsCode}
									onChange={(e) => setSelectedTermsCode(e.target.value)}
								/>
								<p className="fw-bold mt-4 pb-1 mb-0">Country:</p>
								<div className="d-flex justify-content-center w-100">
									<Form.Select
										placeholder="Country"
										name="country"
										value={
											selectedTermsCountry === ""
												? `Country`
												: selectedTermsCountry
										}
										onChange={(e) => setSelectedTermsCountry(e.target.value)}
										style={{
											border: "none",
											borderBottom: "1px solid #255DAB",
											borderRadius: "0px",
											color: "#787878",
											width: "180px",
										}}
									>
										{/* Add options for the dropdown */}
										<option disabled selected hidden>
											Country
										</option>
										<CountriesList />
									</Form.Select>
								</div>

								<p className="fw-bold mt-4 pb-1 mb-0">Language:</p>
								<div className="d-flex justify-content-center w-100">
									<Form.Select
										placeholder="Language"
										name="language"
										value={
											selectedTermsLanguage === ""
												? `Language`
												: selectedTermsLanguage
										}
										onChange={(e) => setSelectedTermsLanguage(e.target.value)}
										style={{
											border: "none",
											borderBottom: "1px solid #255DAB",
											borderRadius: "0px",
											color: "#787878",
											width: "180px",
										}}
									>
										{/* Add options for the dropdown */}
										<option disabled selected hidden>
											Language
										</option>
										<option value="English-UK">English-UK</option>
										<option value="English-US">English-US</option>
										<option value="Swiss-German">Swiss-German</option>
										<option value="Spanish">Spanish</option>
										<option value="Italian">Italian</option>
									</Form.Select>
								</div>
							</Col>
						</Row>
						<Row className="justify-content-center">
							<Col lg={9} className="text-center">
								<Suspense fallback={<div>Loading...</div>}>
									{ReactQuill && (
										<div className="py-4" style={{ height: "30rem" }}>
											<ReactQuill
												theme="snow"
												value={selectedTermsContent}
												onChange={setSelectedTermsContent}
												className="h-100   text-black"
											/>
										</div>
									)}
								</Suspense>
							</Col>
						</Row>
						<Row className="justify-content-center">
							<Col lg={9} className=" my-5">
								<Row>
									<Col className="text-start" lg={6}>
										<Button
											className="px-5 text-white"
											variant="red"
											onClick={() =>
												handleDelete(selectedEvent, selectedEventId)
											}
										>
											Delete
										</Button>
									</Col>
									<Col className="text-end" lg={6}>
										<Button
											className="px-5 text-white"
											variant="dark-bg"
											onClick={handleButtonClick}
										>
											Save
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				</Modal.Body>
			</Modal>
			<Modal
				style={{ zIndex: 99999 }}
				size="xl"
				show={show2}
				onHide={handleClose2}
				centered
			>
				<Modal.Body className=" p-5" closeButton>
					<div className="position-relative">
						<div className="position-absolute  start-0 top-0 ">
							<MdOutlineClear
								style={{ cursor: "pointer" }}
								className="fs-4"
								onClick={handleClose2}
							/>
						</div>
						<div className="text-center">
							<p className="fw-bold pb-1 mb-0">Title:</p>
							<input
								style={{
									borderTop: "0px",
									borderRight: "0px",
									borderLeft: "0px",
									borderBottom: "0px",
								}}
								className="fs-3 w-80 text-center fw-bold"
								type="text"
								value={newTermsName}
								onChange={(e) => setNewTermsName(e.target.value)}
							/>
						</div>
						<hr />
						<Row>
							<Col className="text-center">
								<p className="fw-bold pb-1 mb-0">Code:</p>
								<input
									style={{
										border: "none",
										borderBottom: "1px solid #255DAB",
										borderRadius: "0px",
										color: "#787878",
										width: "180px",
									}}
									className="fs-6  text-center text-med-grey "
									type="text"
									value={newTermsCode}
									onChange={(e) => setNewTermsCode(e.target.value)}
								/>
								<p className="fw-bold mt-4 pb-1 mb-0">Country:</p>
								<div className="d-flex justify-content-center w-100">
									<Form.Select
										placeholder="Country"
										name="country"
										value={newTermsCountry === "" ? `Country` : newTermsCountry}
										onChange={(e) => setNewTermsCountry(e.target.value)}
										style={{
											border: "none",
											borderBottom: "1px solid #255DAB",
											borderRadius: "0px",
											color: "#787878",
											width: "180px",
										}}
									>
										{/* Add options for the dropdown */}
										<option disabled selected hidden>
											Country
										</option>
										<CountriesList />
									</Form.Select>
								</div>

								<p className="fw-bold mt-4 pb-1 mb-0">Language:</p>
								<div className="d-flex justify-content-center w-100">
									<Form.Select
										placeholder="Language"
										name="language"
										value={
											newTermsLanguage === "" ? `Language` : newTermsLanguage
										}
										onChange={(e) => setNewTermsLanguage(e.target.value)}
										style={{
											border: "none",
											borderBottom: "1px solid #255DAB",
											borderRadius: "0px",
											color: "#787878",
											width: "180px",
										}}
									>
										{/* Add options for the dropdown */}
										<option disabled selected hidden>
											Language
										</option>
										<option value="English-UK">English-UK</option>
										<option value="English-US">English-US</option>
										<option value="Swiss-German">Swiss-German</option>
										<option value="Spanish">Spanish</option>
										<option value="Italian">Italian</option>
									</Form.Select>
								</div>
							</Col>
						</Row>
						<Row className="justify-content-center">
							<Col lg={9} className="text-center">
								<Suspense fallback={<div>Loading...</div>}>
									{ReactQuill && (
										<div className="py-4" style={{ height: "30rem" }}>
											<ReactQuill
												theme="snow"
												value={newTermsContent}
												onChange={setNewTermsContent}
												className="h-100   text-black"
											/>
										</div>
									)}
								</Suspense>
							</Col>
						</Row>
						<Row className="justify-content-center">
							<Col lg={9} className="text-end my-5">
								<Button
									className="px-5 text-white"
									variant="dark-bg"
									onClick={handleButtonClick2}
								>
									Save
								</Button>
							</Col>
						</Row>
					</div>
				</Modal.Body>
			</Modal>
			<Container className="px-4 pb-5 pt-3 pb-lg-7" fluid>
				<Row className="justify-content-end mb-4">
					<Col lg={2}>
						<AccountBox />
					</Col>
				</Row>
				<Row className="h-100 gx-5">
					<Col style={{ minHeight: "100%" }} lg={1}>
						<LeftNavbar />
					</Col>
					<Col lg={11}>
						<div
							style={{ borderRadius: "10px", minHeight: "400px" }}
							className="w-100 bg-white"
						>
							<Row className="justify-content-between pb-3 pt-4 ps-4 ms-1 pe-6">
								<Col>
									<h2 className="fs-5"> Cancellation Terms and Conditions</h2>
								</Col>
								<Col className="text-end">
									<Filters
										columnFilters={filtering}
										setColumnFilters={setFiltering}
									/>
								</Col>
							</Row>

							<Table hover>
								{renderTableHeader()}
								{loadForm && <tbody>{renderTableBody()}</tbody>}
								{!loadForm && (
									<tbody>
										<tr className="text-center">
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
										</tr>
										<tr className="text-center">
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
										</tr>
										<tr className="text-center">
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
										</tr>
										<tr className="text-center">
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
										</tr>
										<tr className="text-center">
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
										</tr>
									</tbody>
								)}
							</Table>
							<Row className="py-4 align-items-center">
								<Col className="pe-4" lg={12}>
									<Row className="py-4 align-items-center">
										<Col lg={4}>
											<Button
												className="   ms-5  text-white d-flex align-items-center py-3  justify-content-center"
												variant="dark-bg"
												onClick={() => {
													handleShow2();
												}}
											>
												<AiOutlinePlusCircle className="fs-4 me-2 " /> Add new
												terms
											</Button>
										</Col>
										<Col className="text-end" lg={7}>
											<p className="pb-0 mb-0 fw-bold">
												Page {table.getState().pagination.pageIndex + 1} of{" "}
												{table.getPageCount()}
											</p>
										</Col>
										<Col className="" lg={1}>
											<ButtonGroup aria-label="Basic example">
												<Button
													style={{ ountline: "none", border: "none" }}
													variant="outline-black"
													onClick={() => table.previousPage()}
													disabled={!table.getCanPreviousPage()}
													className="mx-0 ps-0"
												>
													<IoIosArrowBack className="fs-2" />
												</Button>
												<Button
													className="mx-0 ps-0 "
													style={{ ountline: "none", border: "none" }}
													variant="outline-black"
													onClick={() => table.nextPage()}
													disabled={!table.getCanNextPage()}
												>
													<IoIosArrowForward className="fs-2" />
												</Button>
											</ButtonGroup>
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};
export default CancellationTermTable;
